import { createApp } from 'vue'

import App from './App.vue'
// Import the functions you need from the SDKs you need
//import { initializeApp } from "firebase/app";

import router from './router'
// import { getAnalytics } from "firebase/analytics";

// import {firebaseConfig} from './utils/firebaseConfig'

// Initialize Firebase
//const app = initializeApp(firebaseConfig);
//const analytics = getAnalytics(app);

//console.log(analytics);
//import router from './router'


const app =createApp(App).use(router)
// Make sure to _use_ the router instance to make the
// whole app router-aware.
//app.use(router)

app.mount('#app')

