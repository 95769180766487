<template>
  <HeaderMenuVue/>

  <router-view/>
  <FooterViewVue/>
  
</template>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

nav {
  padding: 30px;
}

nav a {
  font-weight: bold;
  color: #2c3e50;
}

nav a.router-link-exact-active {
  color: #42b983;
}
</style>
<script>
// @ is an alias to /src

import HeaderMenuVue from '@/components/HeaderMenu.vue';
import FooterViewVue from './views/FooterView.vue';

export default {
  name: 'HomeView',
  components: {
    HeaderMenuVue,
    
    FooterViewVue
    

 
  }
}
</script>