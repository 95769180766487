<template>
  <div class="header-menu">
      <img class="img-principal" src="../assets/img/EL_COMPADRE.png" alt="">
    <nav class="navbar navbar-expand-lg navbar-light bg-light">
    <button class="navbar-toggler position-relative" style="left: 80%;" type="button" data-toggle="collapse"
      data-target="#navbarText" aria-controls="navbarText" aria-expanded="false" aria-label="Toggle navigation">
      <span class="navbar-toggler-icon"></span>
    </button>
    <div class="collapse navbar-collapse menu-movil" id="navbarText">
      <ul class="navbar-nav m-auto">
        <li class="nav-item active">
          
          <router-link to="/" class="nav-link">Inicio</router-link>
        </li>
       
        
        <li class="nav-item">
          <router-link to="/menu" class="nav-link">Menú</router-link>
   
          
        </li>
        
       
      </ul>
    </div>
  </nav>
  </div>
</template>

<script>
export default {
  name: 'HeaderMenu',
  props: {
   
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
