<template>
    <div class="container-fluid fondo-contacto" id="contacto">
    <h3 class="titulo-about py-5"><strong>Estamos para servirte, contáctanos</strong></h3>
    <br>
    <div class="row">
        <div class="col-12 col-md-8 col-lg-6 col-xl-5">
            <div class="card-contacto">
                <div class="row p-3">
                    <img src="../assets/img/el-compadre-logo.png" class="w-100" style="height: 3rem;">				
                </div>		
                
                <h5><span class="icon-phone pr-3"></span> <a  href="tel:9831451917">983 145 1917</a>.</h5>				
                <h5> <span class="icon-facebook-with-circle pr-3"></span><a href="https://www.facebook.com/carnitaselcompadre">@carnitaselcompadre.</a></h5>
            </div>
        </div>
        <div class="col-12 col-md-4">
            <div class="box-img efecto-movimiento">
                <img src="../assets/img/compadre-02.png" class="w-100">
            </div>
        </div>
    </div>
</div>

<div class="container px-3 text-center" id="ubicacion">
    <h1 class="py-5">Mapa de localización</h1>
    <br>
    <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3782.9298579255933!2d-88.29593898606656!3d18.532071573661465!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x8f5ba48e0d97ca19%3A0x69f9e2d136e88725!2sCarnitas+el+compadre!5e0!3m2!1ses-419!2smx!4v1521141696886" width="100%" height="400" frameborder="0" style="border:0" allowfullscreen></iframe>

</div>
    
</template>
<style >

.fondo-contacto { background-image: url("../assets/img/fondo-01.jpg"); background-size: cover; background-position: center bottom; width: 100%; }
a{color: #8c878c}
a:hover {color:#8c878c}

.titulo-about { padding: 1rem 2rem; color: white; text-align: center; }
</style>
<script>
export default {
  name: 'AboutComponent',
  props: {
   
  }
}
</script>
