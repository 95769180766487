

class Message {

    send_message(phone,text){
        return `https://api.whatsapp.com/send?phone=52${phone}}&text=${encodeURIComponent(text)}`
    }
}


export { Message}