<template>

  <div class="container-fluid p-3 h-100 fondo-color-01">
    <div class="menu-box p-4 text-center text-white">
      <h1>ordenar</h1>
      
      </div>
      <div class="container col-md-4 ">
        <table class="table mt-4 text-white col-md-8"> 
        <thead>
          <tr>
            <td>Árticulo</td>
            <td>Precio</td>
            <td>Cantidad</td>
            <td>Sub total</td>

          </tr>
         
        </thead>
        <tbody>
          <tr v-for="item in carrito" v-bind:key="item.name"  class="text-white">
            <td>{{item.product}}</td>
            <td>${{item.price}}</td>
            <td>{{item.quantity}}</td>
            <td> ${{item.total}}</td>
          </tr>
          <tr>
            <td></td>
            <td></td>
            <td></td>
            <td>Total : ${{total}}</td>
          </tr>
        </tbody>
      </table>
      </div>
     
      
      
     <div class="container">
        <h4 class="text-white">Datos de envio</h4>
        <div  class="form-group" >

            <div class="form-group row">
                <div class="col-md-6">
                    
                    <input v-model="address.street_1" class="form-control " type="text" placeholder="Calle principal">
                </div>
            
                <div class="col-md-6">
                  <input v-model="address.street_2" class="form-control " type="text" placeholder="cruzamientos">

                  </div>
            </div>

            <div class="form-group row">
              <div class="col-md-4">
                    
                    <input v-model="address.number_ext" class="form-control " type="text" placeholder="Número de casa">
                </div>
                <div class="col-md-4">
                    
                    <input v-model="address.tel" class="form-control " type="text" placeholder="Número telefónico" maxlength="10">
                </div>
                <div class="col-md-4">
                    
                    <input v-model="address.neighborhood" class="form-control " type="text" placeholder="Colonia">
                </div>
            </div>
            <div class="form-group">
              <textarea v-model="address.extra_info" name="" id="" cols="30" rows="10" placeholder="escriba como quiere su pedido si maciza, surtido ..." class="form-control"></textarea>
            </div>
              <button class="btn btn-primary" @click="saveOrder">Enviar</button>
          </div>
     </div>
  </div>
</template>

<style>
h1 {
  color:white
}
</style>
<script>
//import {OrdersDataService} from '@/services/OrderService.js'
import {Message} from "@/services/Messages"
export default {
  name: 'OrderForm',
  props: {
   
  },
  data(){
      return  {

        address: {
          street_1: '',
          street_2: '',
          number_ext: '',
          neighborhood: '',
          tel: '',
          extra_info: '',


        },
        total: this.getTotalCarrito(),
        carrito: this.getCarrito(),
      }
  },
  methods: {

    saveOrder(){
     //let  services_order = new OrdersDataService();
     let message = new Message();
     /*let order = {
        'address':this.address,
        'order':this.carrito
     }*/
     //let order_send = services_order.create(order);
     //order_send.then((data)=> console.log(data)).catch((error)=> console.log(error));
     let message_text = ""
     Object.keys(this.carrito).forEach(element => {
          
          message_text += `${this.carrito[element].quantity} :${this.carrito[element].product}  `
        })
    
      message_text += `Enviar a la dirección: ${this.address.street_1} entre ${this.address.street_2} # ${this.address.number_ext} colonia:  ${this.address.neighborhood} `
      message_text +=  ` info extra : ${this.address.extra_info} `
      let phone_local = "9831338167"
      window.open(message.send_message(phone_local,message_text));
    },

    getTotalCarrito(){

      let totalCarrito =  localStorage.getItem('total_carrito');

      if(totalCarrito == null){
        totalCarrito  = 0;
      }
      else if(isNaN(totalCarrito) === true)
      {
        totalCarrito  = 0;
      
      }

      


      return  parseInt(totalCarrito)
    },

    getCarrito()
    {
        let carrito = localStorage.getItem('carrito');
        if(carrito == undefined || carrito == null){
          carrito =  '{}';
        }
        return JSON.parse(carrito);
    }
  },
  components: {}
    }
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
