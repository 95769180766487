<template>
  <div class="slider-img">
     <div class="container-fluid p-4">
    <div id="carouselExampleIndicators" class="carousel slide" data-ride="carousel">
      <ol class="carousel-indicators">
        <li data-target="#carouselExampleIndicators" data-slide-to="0" class="active"></li>
        <li data-target="#carouselExampleIndicators" data-slide-to="1"></li>
       
      </ol>
      <div class="carousel-inner">
        
        <div class="carousel-item active">
          <img class="d-block w-100" src="../assets/img/02.jpg" alt="Second slide">
          <div class="carousel-caption d-none d-md-block">
            <h5>Lo mejor de la comida microacana</h5>
            <p>esta con "El compadre"</p>
          </div>
        </div>
        <div class="carousel-item">
          <img class="d-block w-100" src="../assets/img/01.jpg" alt="Third slide">
        </div>
      </div>
      <a class="carousel-control-prev" href="#carouselExampleIndicators" role="button" data-slide="prev">
        <span class="carousel-control-prev-icon" aria-hidden="true"></span>
        <span class="sr-only">Previous</span>
      </a>
      <a class="carousel-control-next" href="#carouselExampleIndicators" role="button" data-slide="next">
        <span class="carousel-control-next-icon" aria-hidden="true"></span>
        <span class="sr-only">Next</span>
      </a>
    </div>
  </div>
  <br>
  </div>
</template>

<script>
export default {
  name: 'SliderImg',
  props: {
   
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
