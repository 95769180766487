<template>
<SliderImgVue/>
<AboutComponent/>
 
</template>

<script>
// @ is an alias to /src

import SliderImgVue from '@/components/SliderImg.vue';
import AboutComponent from '@/components/AboutComponent';
export default {
  name: 'HomeView',
  components : {
    SliderImgVue,
    AboutComponent
  }
}
</script>
