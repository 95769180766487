import { createRouter,createWebHashHistory } from 'vue-router'
import HomeView from './views/HomeView.vue'

import MenuProducts from './components/MenuProducts'

import OrderForm from './components/OrderForm'


const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView
  },
  {
    path: '/menu',
    name: 'menu',
    component: MenuProducts
  },
  {
    path: '/ordenar',
    name: 'ordenar',
    component: OrderForm
  },
]

const router = createRouter({
  history: createWebHashHistory(process.env.BASE_URL),
  mode:'history',
  routes
})

export default router
