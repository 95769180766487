<template>
  <div class="menu-products">
     <div class="container-fluid p-0 h-100 fondo-color-01" id="menu">
    <div class="menu-box p-4 text-center text-white">
      <img src="../assets/img/compadre-01.png" class="personaje-01">

      <div class="row color-fondo-06">
        <div class="container contenido-menu text-center pt-5">
          <h1>Menú</h1>
          <h5 style="font-style: italic;">"Vive al estilo Chetumal probando las carnitas al estilo Michoacán"</h5>

        
          <table class="table mt-4 t-menu">
            <thead>
              <tr>
                <th>Descripción</th>
                <th>Precio</th>
                <th>Ordenar</th>
              </tr>
            </thead>
            <tbody>

              <tr v-for="item in products" v-bind:key="item.name">
                <td>{{item.description}}</td>
                <td>$ {{item.price}}</td>
                <td> 
                  <button class="btn" @click="addToCount(item)">+</button>
                  <input :value="getQuantity(item)" v-on:change="editQuantity($event, item)" class="form-input input-quantity">
                  <button class="btn" @click="removeToCount(item)">-</button>
                  

                </td>
              </tr>
              <tr>
                <td></td>
                <td>Total</td>
                <td>
                  <h3> ${{total}}</h3>

                    <div class="container">
                        <div class="row ">
                          <div class="col-md-3 offset-3">
                            <router-link to="/ordenar" class="btn btn-success mg-1">Ordenar</router-link>
                          </div>
                      
                          <div class="col-md-3 ">
                            <button @click="clearCarrito()" class="btn btn-warning">Vaciar carrito</button>
                          </div>
                    
                        </div>
                    </div>
                    

            
                  
                  

                </td>
              </tr>
             
            </tbody>
          </table>

          
          
          <p>Todos los precios son dados en Moneda Nacional Mexicana</p>
        </div>
        <div class="fondo-menu">
        </div>
      </div>
    </div>
  </div>
  </div>
</template>

<style>
.input-quantity {
  width: 30px;
  text-align: center;
  margin:0.5em;
}
</style>
<script>




export default {
  name: 'MenuProducts',
  props: {
  
  },
  data() {

         return {
        
           total: this.getTotalCarrito(),
            carrito: this.getCarrito(),
            products : [
              {
                price: 320.0,
                name: "Kg carnitas",
                description : "1 Kg, con 30 tortillas"
              },
              {
                price: 240.0,
                name: "3/4 kg de carnitas",
                description : "3/4 kg, con 25 tortillas"
              },
              {
                price: 160.0,
                name: "1/2 kg de carnitas",
                description : "1/2 kg, con 15 tortillas"
              },
              {
                price: 80.0,
                name: "1/4 kg de carnitas",
                description : "1/4 kg, con 8 tortillas"
              },
              {
                price: 20.0,
                name: "Tacos",
                description : "Tacos"
              },
              {
                price: 20.0,
                name: "Refresco",
                description : "Refresco"
              },
              {
                price: 3.0,
                name: "Tortilla extra",
                description : "Tortilla por unidad"
              },
              {
                price: 10.0,
                name: "salsa extra",
                description : "Salsa verde, salsa tipo guacamole, pico de gallo y salsa roja"
              },

            ]
         }
      },
 
  methods:{

    clearCarrito(){
      this.carrito = {};
      this.total =0;
      localStorage.setItem('carrito',JSON.stringify({}));
      localStorage.setItem('total_carrito',0);
    },
    getTotalCarrito(){

      let totalCarrito =  localStorage.getItem('total_carrito');

      if(totalCarrito == null){
        totalCarrito  = 0;
      }
      else if(isNaN(totalCarrito) === true)
      {
        totalCarrito  = 0;
  
      }

      
      return  parseInt(totalCarrito)
    },

    getCarrito(){
      let carrito = localStorage.getItem('carrito');
      if(carrito == undefined || carrito == null){
        carrito =  '{}';
      }
      return JSON.parse(carrito);
    },
    saveCarrito(){
        localStorage.setItem('carrito',JSON.stringify(this.carrito));
        localStorage.setItem('total_carrito',this.total);
    },
    editQuantity: function(event,product){

        const new_quantity = event.srcElement.value;

        if (new_quantity == 0){
          delete this.carrito[product.name];
        }
        else{
          this.carrito[product.name]  = {
            quantity : new_quantity,
            product: product.name,
            price: product.price,
            total: new_quantity * product.price
          }

        }
        

        this.calculateTotal();
      
    },
    getQuantity : function(product){
     
      
     
      if(product.name in this.carrito ){
        let item = this.carrito[product.name]
        return item.quantity
        
      }
      else{
        return 0
      }
    },

    removeToCount: function(product){

      
      var item  = this.carrito[product.name]
      let update_quantity = 0
        if(item === undefined)
        {
          console.log()
        }
        else{

          if(item.quantity > 0 ) {
               update_quantity = item.quantity - 1;
          
          }
          else {
             update_quantity = 0;
             
           
      
          }

          this.carrito[product.name] = {
            quantity : update_quantity,
            product: product.name,
            price: product.price,
            total: product.price * update_quantity

          }
          
          if (update_quantity == 0)
          {
            delete this.carrito[product.name];
          }

          
        }
       this.calculateTotal()

    },
    calculateTotal:function(){
      let total = 0;
        Object.keys(this.carrito).forEach(element => {
          
          total = total + this.carrito[element].total;
        })

        this.total =  total;
      this.saveCarrito();
    },
    addToCount: function(product){
      
        var item  = this.carrito[product.name]
        if(item === undefined)
        {
          this.carrito[product.name]  = {
            quantity : 1,
            product: product.name,
            price: product.price,
            total: 1 * product.price
          }
        }
        else{
          const update_quantity = item.quantity +1;
          this.carrito[product.name] = {
            quantity : update_quantity,
            product: product.name,
            price: product.price,
            total: product.price * update_quantity

          }
        }
        this.calculateTotal();
    },
    
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
